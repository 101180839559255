.container {
  display: flex;
  flex-direction: column;

  .map_wrapper {
    width: 100%;
    height: 100vh;
  }
  .map:-webkit-full-screen {
    height: 100%;
    margin: 0;
  }
  .map:-ms-fullscreen {
    height: 100%;
  }
  .map:fullscreen {
    height: 100%;
  }

  .customControl {
    left: 300px;
    bottom: 300px;
    color: red;
  }

  .ol_popup {
    position: absolute;
    background-color: white;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    padding: 10px 0 10px 10px;
    border-radius: 10px;
    border: 1px solid #cccccc;
    bottom: 12px;
    left: -50px;
    cursor: default;
    font-size: 13px;

    .sessionsContainer {
      padding-right: 15px;
    }

    div {
      margin-top: -5px;

      .subHeader {
        color: #40a9ff;
        padding-left: 5px;
        margin: 0;
      }

      button {
        display: flex;
        width: 100%;
        height: 23px;
        font-size: 11px;
        padding: 0;
        margin-top: 15px;
      }
    }

    .popupData {
      display: flex;
      flex-direction: column;
      font-style: italic;
      color: #40a9ff;
      max-height: 250px;
      overflow-y: auto;
      width: 240px;
      padding-right: 20px;

      .visitedCount {
        flex-direction: row;
        margin-bottom: 10px;

        b {
          color: #40a9ff;
        }
      }

      .timeIntervals {
        color: dimgray;
        padding-left: 10px;
        margin: 0;
      }

      .title {
        margin: 2px 0 10px 0;
        font-size: 15px;
        text-align: left;
      }

      .date span {
        width: 100%;
      }

      span {
        text-align: left;
        max-width: 150px;
        padding-left: 10px;
        color: dimgray;
        line-height: 17px;
      }

      .dtpInfo {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        .dtp {
          max-width: unset;
        }

        span {
          padding: 0;
        }
      }

      .pochtaVisit span {
        max-width: unset;
      }

      div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
      }

      .bikeSession,
      .pochtaVisit {
        b {
          color: #40a9ff;
        }

        width: 100%;
        border: 1px dashed #40a9ff;
        border-radius: 4px;
      }

      .bikeSessionMargin,
      .pochtaVisitMargin {
        margin-bottom: 12px;
      }

      .lastItem {
        padding-bottom: 5px;
      }
    }

    .smallPopupData {
      width: 180px;
      div {
        margin-bottom: 5px;
      }
    }

    .pochtaPopupData {
      width: 250px;
    }

    .dtpPopupData {
      width: 270px;

      span {
        max-width: unset;
      }
    }
  }
  .ol_popup:after,
  .ol_popup:before {
    top: 100%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  .ol_popup:after {
    border-top-color: white;
    border-width: 10px;
    left: 48px;
    margin-left: -10px;
  }
  .ol_popup:before {
    border-top-color: #cccccc;
    border-width: 11px;
    left: 48px;
    margin-left: -11px;
  }
  .ol_popup_closer {
    text-decoration: none;
    position: absolute;
    top: 0;
    right: 0;
    padding: 7px 8px;
    color: #d32f2f;
  }
  .ol_popup_closer:after {
    content: '✖';
  }
}

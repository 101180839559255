.personInfoHeader {
  margin-bottom: 32px;
}

.block {
  padding: 20px;
}

.gallery {
  button svg {
    height: 24px !important;
    width: 24px !important;
    font-weight: 700;
    color: white;
  }
}

@media (max-width: 768px) {
  .personTransport {
    justify-content: center;

    .block {
      min-width: unset;
      width: 100%;
      margin-right: 0;

      .gallery {
        min-width: unset;
        width: 100%;
        height: 100%;
      }

      .textInfoBlock {
        display: flex;
        flex-wrap: wrap;

        b,
        span {
          width: 100%;
        }

        span {
          word-break: break-all;
        }
      }
    }
  }

  .personInfoHeader {
    justify-content: left;
    flex-wrap: wrap;
    margin-bottom: 1rem;

    h5 {
      font-size: 1.6rem;
    }

    button {
      margin-top: 5px;
    }
  }

  .subheader {
    font-size: 1.4rem;
  }
}

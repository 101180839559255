.map_wrapper {
  width: 100%;
  height: 670px;
  padding: 2%;
}

.ol_popup {
  position: absolute;
  background-color: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  bottom: 12px;
  left: -50px;
  min-width: 205px;
  cursor: default;
  font-size: 13px;

  div {
    margin-top: -5px;
    margin-bottom: 5px;

    span {
      color: #1565c0;
    }

    button {
      display: flex;
      width: 100%;
      height: 23px;
      font-size: 11px;
      padding: 0;
      margin-top: 15px;
    }
  }
}
.ol_popup:after,
.ol_popup:before {
  top: 100%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.ol_popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}
.ol_popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}
.ol_popup_closer {
  text-decoration: none;
  position: absolute;
  top: 2px;
  right: 8px;
  color: #d32f2f;
}
.ol_popup_closer:after {
  content: '✖';
}

.react-swipeable-view-container {
  width: 100%;
  height: 100%;
  align-items: center;

  div[data-swipeable] {
    display: flex;
    width: 100%;
    height: 100%;
  }
}

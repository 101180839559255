.map {
  width: 100%;
  height: 600px;
}

.ol-popup {
  position: absolute;
  background-color: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  bottom: 12px;
  left: -50px;
  min-width: 280px;
}

.ol-popup:after,
.ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.ol-popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}

.ol-popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}

.ol-popup-closer {
  text-decoration: none;
  position: absolute;
  top: 0px;
  right: 0px;
}

.popupContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.popupContainer p {
  width: 80px;
  text-align: start;
  margin-top: 8px;
  margin-bottom: 0;
  color: #030303;
  font-size: 14px;
  line-height: 16px;
}

.popupContainer .alignEnd {
  text-align: end;
  align-items: flex-end;
  width: 100%;
}

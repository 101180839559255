.wrapper {
  border-radius: 12px;
  background-color: #fff;
  margin-top: 15px;
  padding: 24px;
  height: 100vh;
  overflow-y: auto;

  .card_wrapper {
    max-height: 710px;
    margin-top: 24px;
  }
}

@media (min-width: 1500px) {
  .wrapper .card_wrapper {
    width: 1200px;
  }
}

@media (min-width: 1800px) {
  .wrapper .card_wrapper {
    width: 1450px;
  }
}

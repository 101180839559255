.downloadContainer {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgb(64, 169, 255, 0.3);

  .bgContainer {
    height: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -100px;

    img {
      height: 50%;
      width: 100%;
    }
  }

  .buttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      border-radius: 6px;
      border: 1px solid #fff;
      width: 150px;
      height: 40px;
      background-color: #40a9ff;

      svg {
        margin-right: 5px;
      }

      a {
        color: white;
      }
    }
  }
}

.Loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 10;
  background: #fff;
  backdrop-filter: blur(2px);
  border-radius: 32px;
  height: 100%;
}

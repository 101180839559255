.link:visited,
.link,
.link:focus,
.link:active {
  border: none;
}

.personInfoHeader {
  margin-bottom: 32px;
}

.block {
  width: 32%;
  min-width: 360px;

  .textInfoBlock {
    align-items: flex-start;

    b,
    span {
      width: 50%;
    }

    span {
      word-break: break-all;
    }
  }
}

.gallery {
  min-width: 360px;
  width: 32%;
  height: 360px;

  button svg {
    height: 24px !important;
    width: 24px !important;
    font-weight: 700;
    color: white;
  }
}

@media (max-width: 768px) {
  .personInfo {
    justify-content: center;

    .block {
      min-width: unset;
      width: 100%;
      margin-right: 0;

      .gallery {
        width: 100%;
        min-width: unset;
        height: 100%;
      }

      .textInfoBlock {
        display: flex;
        flex-wrap: wrap;

        b,
        span {
          width: 100%;
        }

        span {
          word-break: break-all;
        }
      }
    }
  }

  .personInfoHeader {
    justify-content: left;
    flex-wrap: wrap;
    margin-bottom: 16px;

    h5 {
      font-size: 1.6rem;
    }

    button {
      margin-top: 5px;
    }
  }

  .subheader {
    font-size: 1.4rem;
  }
}

.wrapper {
  max-height: 100vh;
  overflow-y: scroll;
}

.loading {
  display: none;
}

.transportTable {
  max-width: 900px;
  margin: 0 auto;
  margin-bottom: 24px;

  .ant-spin-nested-loading {
    height: 460px;
    max-height: 50%;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 900px;
  margin: 0 auto;
}

.grzWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.image {
  cursor: pointer;
}

.field {
  b {
    color: #40a9ff;
  }
}

.grzWrapper {
  color: #40a9ff;
}

@media (max-width: 500px) {
  .saveButton {
    margin: 0;
  }
  .header {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 0;
    margin-bottom: 15px;
    justify-content: center;
    align-items: center;
  }
}

.image-gallery-slide img {
  min-height: 200px;
}

.image-gallery-icon.image-gallery-fullscreen-button {
  padding: 0;
}

.image-gallery-icon.image-gallery-fullscreen-button svg {
  color: black;
  background-color: #fff;
  width: 25px !important;
  height: 25px !important;
  margin: 10px;
}

.image-gallery-right-nav .image-gallery-svg,
.image-gallery-left-nav .image-gallery-svg {
  border: 1px solid white;
  border-radius: 24px;
  background-color: white;
  color: black;
}

@media (max-width: 768px) {
  .image-gallery-icon.image-gallery-fullscreen-button svg {
    color: white;
    background-color: #5578eb;
    padding: 5px;
    margin: 0;
  }

  .fullscreen .image-gallery-icon.image-gallery-fullscreen-button svg {
    margin-right: 10px;
  }

  .image-gallery,
  .image-gallery-content.fullscreen {
    background-color: white;
  }

  .fullscreen .image-gallery-slides .image-gallery-image {
    height: 100vh;
    margin: 0 auto;
    width: 100vw;
  }

  .image-gallery-left-nav,
  .image-gallery-right-nav {
    display: none;
  }
}
